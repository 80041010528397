<template>
  <section class="container pb-8">
    <div class="row">
      <article class="cs-nav col-12 mb-6" data-style="2">
        <ul class="nav nav-pills nav__bar" id="pills-tab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="pills-home-tab"
              data-toggle="pill"
              href="#pills-home"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              >全部訂單</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="pills-profile-tab"
              data-toggle="pill"
              href="#pills-profile"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
              >待付款訂單</a
            >
          </li>
        </ul>
        <div class="tab-content nav__content p-5 p-md-8" id="pills-tabContent">
          <!-- 全部訂單 -->
          <div
            class="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <ul class="cs-table mb-6" data-style="3">
              <li class="table__head d-none d-md-flex">
                <div class="table__head-td">訂單編號</div>
                <div class="table__head-td">處理進度</div>
                <div class="table__head-td">付款方式</div>
                <div class="table__head-td">訂單金額</div>
                <div class="table__head-td">購買時間</div>
              </li>
              <li v-for="item in PageInfo.data" :key="item.orderId" class="table__item">
                <div class="table__item-td table__item--serial">
                  <router-link :to="`/member/orders/${item.orderId}`">
                    {{ item.orderCode }}
                  </router-link>
                </div>
                <div class="table__item-td table__item--process">{{ item.statusName }}</div>
                <div class="table__item-td table__item--method">{{ item.orderPayModeName }}</div>
                <div class="table__item-td table__item--count">
                  {{ item.orderActualPrice | toCurrency }}
                </div>
                <div class="table__item-td table__item--date">
                  {{ item.orderDate | toTaipei | moment('YYYY-MM-DD HH:mm:ss') }}
                </div>
              </li>
            </ul>
            <!-- 分頁 -->
            <Pagination
              @GetList="GetList()"
              :page.sync="Pagination.pageIndex"
              :page-row="Pagination.pageSize"
              :total-count="PageInfo.count"
            />
          </div>
          <!-- 待付款訂單 -->
          <div
            class="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <ul class="cs-table mb-6" data-style="3">
              <li class="table__head d-none d-md-flex">
                <div class="table__head-td">訂單編號</div>
                <div class="table__head-td">付款方式</div>
                <div class="table__head-td">訂單金額</div>
                <div class="table__head-td">購買時間</div>
                <div class="table__head-td"></div>
              </li>
              <li class="table__item" v-for="item in PageInfoByMid.data" :key="item.orderId">
                <div class="table__item-td table__item--serial">
                  <router-link :to="`/member/orders/${item.orderId}`">
                    {{ item.orderCode }}
                  </router-link>
                </div>
                <div class="table__item-td table__item--method">{{ item.orderPayModeName }}</div>
                <div class="table__item-td table__item--count">
                  {{ item.orderActualPrice | toCurrency }}
                </div>
                <div class="table__item-td table__item--date">
                  {{ item.orderDate | toTaipei | moment('YYYY-MM-DD HH:mm:ss') }}
                </div>
                <div class="table__item-td table__item--btn">
                  <router-link
                    :to="`/shopping/step2/${item.orderId}`"
                    class="btn btn-primary border-radius-l px-6"
                    v-if="item.payStatus === 1"
                  >
                    結帳
                  </router-link>

                  <button
                    v-if="(item.isCancelBtn & 2) === 2"
                    class="btn btn-outline-primary border-radius-l"
                    :class="(item.isCancelBtn & 1) === 1 ? 'mr-2' : ''"
                    @click="CopyOrderById(item)"
                  >
                    重新下單
                  </button>

                  <button
                    v-if="(item.isCancelBtn & 1) === 1"
                    class="btn btn-outline-danger border-radius-l"
                    @click="CancelOrderById(item)"
                  >
                    取消訂單
                  </button>
                </div>
              </li>
            </ul>
            <!-- 分頁 -->
            <Pagination
              @GetList="GetListByMid()"
              :page.sync="PaginationByMid.pageIndex"
              :page-row="PaginationByMid.pageSize"
              :total-count="PageInfoByMid.count"
            />
          </div>
        </div>
      </article>
    </div>
  </section>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import {
  GetOrders, GetOrdersByMidState, CancelOrderById, CopyOrderById,
} from '@/api/OrderApi';

export default {
  components: {
    Pagination,
  },
  data() {
    // 建立你的初始化 model data
    return {
      Pagination: {
        // 有分頁要有此model data
        pageIndex: 1, // 預設從第幾頁開始
        pageSize: 6, // 預設每頁幾筆
        data: {},
      },
      PageInfo: {
        count: 0, // api給的總筆數
        data: [],
      },
      PaginationByMid: {
        // 有分頁要有此model data
        pageIndex: 1, // 預設從第幾頁開始
        pageSize: 6, // 預設每頁幾筆
        data: {},
      },
      PageInfoByMid: {
        count: 0, // api給的總筆數
        data: [],
      },
    };
  },
  // 當有設定keep的時候，返回到此頁面會執行這個，不會執行created()，所以這邊就不用放created()
  async activated() {
    if (!this.$route.meta.isBack) {
      // 不是從指定頁面返回的話，進行初始化設定
      this.Pagination = {
        pageIndex: 1, // 預設從第幾頁開始
        pageSize: 6, // 預設每頁幾筆
        data: {
          categoryId: 0,
        },
      };
      this.PaginationByMid = {
        pageIndex: 1, // 預設從第幾頁開始
        pageSize: 6, // 預設每頁幾筆
        data: {
          categoryId: 0,
        },
      };
      this.PageInfo = {
        count: 0, // api給的總筆數
        data: [],
      };
    }
    // 然後再針對頁面進行初始化資料存取
    this.GetList();
    this.GetListByMid();
  },
  // created() {
  //   this.GetList();
  //   this.GetListByMid();
  // },
  mounted() {},
  methods: {
    GetList() {
      GetOrders(this.Pagination).then((response) => {
        if (response.data.statusCode === 0) {
          this.PageInfo.count = response.data.count;
          this.PageInfo.data = response.data.data;
          if (this.Pagination.pageIndex !== 1 && this.PageInfo.data.length === 0) {
            this.Pagination.pageIndex = 1;
            this.GetList();
          }
        }
      });
    },
    GetListByMid() {
      GetOrdersByMidState(this.PaginationByMid).then((response) => {
        if (response.data.statusCode === 0) {
          this.PageInfoByMid.count = response.data.count;
          this.PageInfoByMid.data = response.data.data;
          if (this.PaginationByMid.pageIndex !== 1 && this.PageInfoByMid.data.length === 0) {
            this.GetListByMid();
          }
        }
      });
    },
    CopyOrderById(item) {
      this.$confirm(`確定要將此訂單[${item.orderCode}]重新下單 ?`, '提示', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          CopyOrderById(item.orderId).then((response) => {
            if (response.data.statusCode === 0) {
              this.GetList();
              this.GetListByMid();
              this.$message({
                showClose: true,
                type: 'success',
                message: '已經成功重新下單，請完成繳費作業',
              });
            }
          });
        })
        .catch(() => {});
    },
    CancelOrderById(item) {
      this.$confirm(`確定要將此訂單[${item.orderCode}]取消 ?`, '提示', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          CancelOrderById(item.orderId).then((response) => {
            if (response.data.statusCode === 0) {
              this.GetList();
              this.GetListByMid();
              this.$message({
                showClose: true,
                type: 'success',
                message: '已經成功取消此訂單',
              });
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style></style>
